// Generated by Framer (9bd94da)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["vM4sDLYYw"];

const serializationHash = "framer-M7Trl"

const variantClassNames = {vM4sDLYYw: "framer-v-nf8jvz"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title2, width, ...props}) => { return {...props, kuro5Y6x6: title2 ?? props.kuro5Y6x6 ?? "Blackened"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title2?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, kuro5Y6x6, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "vM4sDLYYw", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-M7Trl", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-nf8jvz", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"vM4sDLYYw"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.h2 style={{"--font-selector": "R0Y7Q29ybW9yYW50IEdhcmFtb25kLXJlZ3VsYXI=", "--framer-font-family": "\"Cormorant Garamond\", \"Cormorant Garamond Placeholder\", serif", "--framer-font-size": "145px", "--framer-letter-spacing": "-0.5px", "--framer-line-height": "0.8em", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-1of0zx5, rgb(255, 255, 255))", "--framer-text-transform": "uppercase"}}>Blackened</motion.h2></React.Fragment>} className={"framer-1xhrdfw"} data-framer-name={"Title"} fonts={["GF;Cormorant Garamond-regular"]} layoutDependency={layoutDependency} layoutId={"k3LMDuZo4"} style={{"--extracted-1of0zx5": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={kuro5Y6x6} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-M7Trl [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-M7Trl .framer-1hjcjyn { display: block; }", ".framer-M7Trl .framer-nf8jvz { height: 116px; overflow: hidden; position: relative; width: 1200px; }", ".framer-M7Trl .framer-1xhrdfw { flex: none; height: auto; left: 0px; position: absolute; right: 0px; top: 50%; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 116
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"kuro5Y6x6":"title2"}
 * @framerImmutableVariables true
 */
const FramerNNNrhDk9A: React.ComponentType<Props> = withCSS(Component, css, "framer-M7Trl") as typeof Component;
export default FramerNNNrhDk9A;

FramerNNNrhDk9A.displayName = "Title";

FramerNNNrhDk9A.defaultProps = {height: 116, width: 1200};

addPropertyControls(FramerNNNrhDk9A, {kuro5Y6x6: {defaultValue: "Blackened", title: "Title 2", type: ControlType.String}} as any)

addFonts(FramerNNNrhDk9A, [{family: "Cormorant Garamond", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/NNNrhDk9A:default", url: "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3bmX5slCNuHLi8bLeY9MK7whWMhyjornFLsS6V7w.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/cormorantgaramond/v16/co3bmX5slCNuHLi8bLeY9MK7whWMhyjornFLsS6V7w.ttf", weight: "400"}])